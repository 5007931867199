import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Button from '@helsenorge/designsystem-react/components/Button';
import Icon from '@helsenorge/designsystem-react/components/Icon';
import ChevronDown from '@helsenorge/designsystem-react/components/Icons/ChevronDown';
import ChevronUp from '@helsenorge/designsystem-react/components/Icons/ChevronUp';
import styles from './styles.module.scss';
const ToggleButton = (props) => {
    return (_jsxs(Button, { "aria-expanded": props.expanded, onClick: props.onClick, wrapperClassName: styles.togglebutton, children: [_jsx(Icon, { svgIcon: props.expanded ? ChevronUp : ChevronDown }), props.expanded ? props.showLess : props.showMore] }));
};
export default ToggleButton;
