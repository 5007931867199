import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Title from '@helsenorge/designsystem-react/components/Title';
import Favorites from './favorites';
import TjenestepanelContainer from './tjenestepanel-container';
import Tjenester from './tjenester';
import TjenesterList from './tjenester-list';
import TjenesterTiles from './tjenester-tiles';
import styles from './styles.module.scss';
const Tjenestepanel = (props) => {
    const favorites = props.favorites ? Object.values(props.favorites)[0] : undefined;
    return (_jsxs(TjenestepanelContainer, { children: [_jsx(Title, { htmlMarkup: 'h1', id: props.titleId, className: styles.tjenestepanel__title, children: props.title }), favorites && favorites.length > 0 && _jsx(Favorites, { favorites: favorites }), _jsx(Tjenester, { tiles: _jsx(TjenesterTiles, { groups: props.groups, sharingStatusText: props.sharingStatusText }), list: _jsx(TjenesterList, { groups: props.groups, sharingStatusText: props.sharingStatusText }), showMore: props.showMore, showLess: props.showLess, noToggle: !(favorites && favorites.length > 0) })] }));
};
export default Tjenestepanel;
