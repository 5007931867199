import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useLayoutEffect, useRef, useState } from 'react';
import { Breakpoint, useBreakpoint } from '@helsenorge/designsystem-react';
import ToggleButton from '../toggle-button';
import styles from './styles.module.scss';
/**
 * Checks if element is completely outside the screen, either below or above the current viewport
 * @param element HTMLElement
 * @param options Scroll options
 */
const scrollIntoViewIfNeeded = (element, options) => {
    const rect = element.getBoundingClientRect();
    const isAboveViewport = rect.bottom < 0;
    const isBelowViewport = rect.top > window.innerHeight;
    if (isAboveViewport || isBelowViewport) {
        element.scrollIntoView(options);
    }
};
const Tjenester = (props) => {
    const [expanded, setExpanded] = useState(false);
    const divRef = useRef(null);
    const groupsRef = useRef(null);
    useLayoutEffect(() => {
        const firstLinkElement = groupsRef.current?.querySelector('a');
        if (expanded && firstLinkElement) {
            scrollIntoViewIfNeeded(firstLinkElement, { behavior: 'smooth', block: 'end' });
        }
        if (!expanded && divRef.current) {
            scrollIntoViewIfNeeded(divRef.current, { behavior: 'auto' });
        }
    }, [expanded]);
    const breakpoint = useBreakpoint();
    const toggleButton = (_jsx(ToggleButton, { showMore: props.showMore, showLess: props.showLess, onClick: () => setExpanded(prev => !prev), expanded: expanded }));
    return (_jsxs("div", { className: styles.tjenester, ref: divRef, children: [!props.noToggle && toggleButton, _jsxs("div", { className: styles.tjenester__groups, ref: groupsRef, children: [breakpoint >= Breakpoint.lg ? props.tiles : props.list, !props.noToggle && toggleButton] })] }));
};
export default Tjenester;
