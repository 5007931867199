import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import LazyIcon from '@helsenorge/designsystem-react/components/LazyIcon';
import Tile from '@helsenorge/designsystem-react/components/Tile';
import { Breakpoint, useBreakpoint } from '@helsenorge/designsystem-react';
import styles from './styles.module.scss';
const Favorites = (props) => {
    const breakpoint = useBreakpoint();
    const favoriteCount = props.favorites.length;
    const useCompact = favoriteCount === 1 || breakpoint >= Breakpoint.lg;
    const classes = classNames(styles.favorites, favoriteCount === 2 && styles['favorites--2'], favoriteCount === 3 && styles['favorites--3']);
    return (_jsx("div", { className: classes, children: props.favorites.map(flis => (_jsx(Tile, { href: flis.link, title: _jsx(Tile.Title, { htmlMarkup: 'h2', children: flis.title }), icon: _jsx(LazyIcon, { iconName: flis.image }), highlighted: flis.highlight, variant: useCompact ? 'compact' : 'normal' }, flis.title))) }));
};
export default Favorites;
