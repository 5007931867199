import { jsx as _jsx } from "react/jsx-runtime";
import Eye from '@helsenorge/designsystem-react/components/Icons/Eye';
import SharingStatus from '@helsenorge/designsystem-react/components/SharingStatus';
import { erTjenester } from '@helsenorge/framework-utils/hn-proxy-service';
import { getHarAktivtForeldreSamtykke, getErRepresentasjon } from '@helsenorge/framework-utils/hn-user';
const SharingStatusWrapper = (props) => {
    if (erTjenester() && getHarAktivtForeldreSamtykke() && getErRepresentasjon()) {
        return (_jsx(SharingStatus, { icon: Eye, color: 'kiwi', children: props.children }));
    }
    return null;
};
export default SharingStatusWrapper;
