import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import Spacer from '@helsenorge/designsystem-react/components/Spacer';
import Title from '@helsenorge/designsystem-react/components/Title';
import PromoSection from '../promosection';
import PortableText from '../richtext';
import styles from './styles.module.scss';
const Articles = props => {
    return (_jsxs("div", { children: [props.title && (_jsxs(_Fragment, { children: [_jsx(Title, { htmlMarkup: "h2", appearance: "title2", children: props.title }), _jsx(Spacer, {})] })), props.articles?.map(article => (_jsx("div", { className: styles.articles__item, children: _jsx(PromoSection, { title: article.tittel, imageUrl: article.imageUrl, imageAltText: article.imageAltText, imageCaption: article.imageCredit, linkText: article.link.displayName, linkUrl: article.link.url, color: "none", hideImageOnMobile: true, children: _jsx(PortableText, { value: article.introduksjon, className: styles['articles__rich-text'] }) }) }, article.tittel)))] }));
};
export default Articles;
