import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef, useState, useEffect } from 'react';
import classNames from 'classnames';
import Button from '@helsenorge/designsystem-react/components/Button';
import Eye from '@helsenorge/designsystem-react/components/Icons/Eye';
import LazyIcon from '@helsenorge/designsystem-react/components/LazyIcon';
import SharingStatus from '@helsenorge/designsystem-react/components/SharingStatus';
import Tile from '@helsenorge/designsystem-react/components/Tile';
import Title from '@helsenorge/designsystem-react/components/Title';
import { erTjenester } from '@helsenorge/framework-utils/hn-proxy-service';
import { getErRepresentasjon, getHarAktivtForeldreSamtykke } from '@helsenorge/framework-utils/hn-user';
import styles from './styles.module.scss';
const Tjenestepanel = ({ fliser, eksterneFliser, eksterneFliserHeader, visFlereButtonText, visFarreButtonText, visAlleFliser, onClickFlis, sharingStatusText, children, }) => {
    const [visFlere, setVisFlere] = useState(!!visAlleFliser);
    const tjenesteRef = useRef(null);
    const moreTilesRef = useRef(null);
    /**
     * Hvor mange fliser som skal vises før man klikker "Vis flere"-knappen
     */
    const INITIAL_FLIS_COUNT = 8;
    useEffect(() => {
        setVisFlere(!!visAlleFliser);
    }, [visAlleFliser]);
    useEffect(() => {
        if (visFlere && !visAlleFliser) {
            moreTilesRef.current?.focus();
        }
        if (visFlere) {
            tjenesteRef.current?.scrollIntoView();
        }
    }, [visFlere]);
    if (fliser.length === 0 && eksterneFliser.length === 0) {
        return null;
    }
    const renderFlis = (flis, index, type) => {
        return (_jsx("div", { className: classNames(styles.tjenestepanel__flis, styles[`tjenestepanel__flis--${type}`]), children: _jsx(Tile, { href: flis.linkUrl, title: _jsx(Tile.Title, { htmlMarkup: "h2", children: flis.title }), description: type === 'intern' ? flis.introduction : undefined, icon: _jsx(LazyIcon, { iconName: flis.iconName }), highlighted: flis.highlight, onClick: (event) => onClickFlis && onClickFlis(event, flis), testId: `tjenestepanel-flis-${type}`, children: erTjenester() &&
                    getHarAktivtForeldreSamtykke() &&
                    getErRepresentasjon() &&
                    flis.delAvForeldresamtykke &&
                    typeof sharingStatusText !== 'undefined' && (_jsx(SharingStatus, { icon: Eye, color: 'kiwi', children: sharingStatusText })) }) }, index));
    };
    return (_jsx("div", { className: styles.tjenestepanel, ref: tjenesteRef, "data-testid": "tjenestepanel", children: _jsxs("div", { className: classNames('container', styles.tjenestepanel__container), children: [_jsx("div", { className: styles.tjenestepanel__row, children: fliser.slice(0, INITIAL_FLIS_COUNT).map((f, i) => renderFlis(f, i, 'intern')) }), _jsx("div", { className: classNames(styles.tjenestepanel__row, styles['tjenestepanel__row--focusable']), "data-testid": "tjenestepanel-flere-fliser", tabIndex: -1, ref: moreTilesRef, style: { outline: 'none' }, children: visFlere && fliser.slice(INITIAL_FLIS_COUNT).map((f, i) => renderFlis(f, i, 'intern')) }), visFlere && eksterneFliser.length > 0 && (_jsxs("div", { className: styles.tjenestepanel__row, children: [_jsx("div", { className: styles.tjenestepanel__header, children: _jsx(Title, { htmlMarkup: "h2", appearance: "title3", children: eksterneFliserHeader }) }), eksterneFliser.slice(0, INITIAL_FLIS_COUNT).map((f, i) => renderFlis(f, i, 'ekstern')), visFlere && eksterneFliser.slice(INITIAL_FLIS_COUNT).map((f, i) => renderFlis(f, i, 'ekstern'))] })), visFlere && children, !visAlleFliser && (_jsx("div", { className: styles.tjenestepanel__row, children: _jsx("div", { className: styles.tjenestepanel__footer, children: _jsx(Button, { onClick: () => {
                                setVisFlere(!visFlere);
                            }, variant: "outline", children: visFlere ? visFarreButtonText : visFlereButtonText }) }) }))] }) }));
};
export default Tjenestepanel;
