import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import Button from '@helsenorge/designsystem-react/components/Button';
import Title from '@helsenorge/designsystem-react/components/Title';
import SearchBox from '../';
import styles from './styles.module.scss';
export const SearchBlock = ({ title, link, linkText, hasWrapper = false, titleHtmlMarkup = 'h3', imageUrl, imageAltText, ...searchBoxProps }) => {
    const searchBlockClasses = classNames(styles.searchblock, !hasWrapper && styles['searchblock--no-wrapper'], imageUrl ? styles['searchblock--with-image'] : styles['searchblock--no-image']);
    return (_jsxs("div", { className: searchBlockClasses, children: [_jsxs("div", { className: classNames(imageUrl && styles.searchblock__mainsection), children: [_jsx(Title, { htmlMarkup: titleHtmlMarkup, className: styles.searchblock__title, appearance: "title3", children: title }), _jsx(SearchBox, { large: false, isSubmitButtonInverted: true, ...searchBoxProps }), link && linkText && (_jsx(Button, { arrow: 'accessibility-character', variant: 'borderless', onColor: 'ondark', htmlMarkup: 'a', href: link, wrapperClassName: styles.searchblock__link, testId: "searchblock-link", children: linkText }))] }), imageUrl && _jsx("img", { className: styles.searchblock__image, src: imageUrl, alt: imageAltText })] }));
};
const SearchBlockWrapper = props => (_jsx("div", { className: styles['searchblock-wrapper'], children: _jsx("div", { className: 'container', children: _jsx("div", { className: 'row', children: _jsx("div", { className: props.imageUrl ? 'col col-12' : 'col col-md-10 offset-md-1 col-lg-8 offset-lg-2', children: _jsx(SearchBlock, { ...props, hasWrapper: true }) }) }) }) }));
export default SearchBlockWrapper;
