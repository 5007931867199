import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import LazyIcon from '@helsenorge/designsystem-react/components/LazyIcon';
import Tile from '@helsenorge/designsystem-react/components/Tile';
import SharingStatus from '../sharing-status';
import TjenesterGroup from '../tjenester-group';
import styles from './styles.module.scss';
const TjenesterTiles = props => {
    return (_jsx(_Fragment, { children: Object.keys(props.groups)
            .filter(title => props.groups[title].length > 0)
            .map(title => (_jsx(TjenesterGroup, { title: title, children: _jsx("div", { className: styles.tjenestertiles, children: props.groups[title].map(flis => (_jsx(Tile, { href: flis.link, title: _jsx(Tile.Title, { htmlMarkup: 'h3', children: flis.title }), description: flis.description, icon: _jsx(LazyIcon, { iconName: flis.image }), highlighted: flis.highlight, children: flis.delAvForeldresamtykke && props.sharingStatusText && _jsx(SharingStatus, { children: props.sharingStatusText }) }, flis.title))) }) }, title))) }));
};
export default TjenesterTiles;
