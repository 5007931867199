import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import Button from '@helsenorge/designsystem-react/components/Button';
import Loader from '@helsenorge/designsystem-react/components/Loader';
import Title from '@helsenorge/designsystem-react/components/Title';
import { LanguageLocales } from '@helsenorge/core-utils/constants/languages';
import { track404 } from '@helsenorge/framework-utils/adobe-analytics';
import Projects from '@helsenorge/framework-utils/constants/projects';
import { isAuthorized } from '@helsenorge/framework-utils/hn-authorize';
import { getTjenesterUrl, getHelsenorgeUrl } from '@helsenorge/framework-utils/hn-proxy-service';
import { getResourcesFromState, fetchResources } from '@helsenorge/framework-utils/resources';
import SideSomIkkeFinnesSvg from './side-som-ikke-finnes-svg';
import { getResources } from '../resources';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import styles from './styles.module.scss';
const PageNotFound = () => {
    const dispatch = useAppDispatch();
    const resources = useAppSelector(state => getResourcesFromState(state, Projects.FRAMEWORK, LanguageLocales.NORWEGIAN));
    const forsideUrl = isAuthorized() ? getTjenesterUrl() : getHelsenorgeUrl();
    React.useEffect(() => {
        dispatch(fetchResources(Projects.FRAMEWORK, LanguageLocales.NORWEGIAN, getResources));
        track404();
    }, []);
    if (!resources) {
        return _jsx(Loader, { overlay: "screen", size: 'medium' });
    }
    return (!!resources && (_jsxs("div", { className: `container mb-6 mb-lg-7 ${styles['page-not-found']}`, children: [_jsx("div", { className: "row", children: _jsxs("div", { className: "col-md-10 offset-md-1 col-lg-8 offset-lg-2", children: [_jsx(Title, { htmlMarkup: "h2", appearance: "title1", children: resources.pageNotFoundHeader }), _jsx("p", { className: styles['page-not-found__preamble'], children: resources.pageNotFoundDescription }), _jsx(Button, { htmlMarkup: "a", href: forsideUrl, children: resources.pageNotFoundToStart })] }) }), _jsx("div", { className: "row", children: _jsx("div", { className: "col-md-10 offset-md-1 col-lg-8 offset-lg-2", children: _jsx("div", { className: "mt-7", children: _jsx(SideSomIkkeFinnesSvg, {}) }) }) })] })));
};
export default PageNotFound;
