import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import LazyIcon from '@helsenorge/designsystem-react/components/LazyIcon';
import LinkList from '@helsenorge/designsystem-react/components/LinkList';
import SharingsStatus from '../sharing-status';
import TjenesterGroup from '../tjenester-group';
import styles from './styles.module.scss';
const TjenesterList = props => {
    return (_jsx(_Fragment, { children: Object.keys(props.groups)
            .filter(title => props.groups[title].length > 0)
            .map(title => (_jsx(TjenesterGroup, { title: title, children: _jsx(LinkList, { chevron: true, color: 'white', className: styles.tjenesterlist, children: props.groups[title].map((tile) => (_jsxs(LinkList.Link, { href: tile.link, icon: _jsx(LazyIcon, { iconName: tile.image }), children: [tile.title, tile.delAvForeldresamtykke && props.sharingStatusText && _jsx(SharingsStatus, { children: props.sharingStatusText })] }, tile.title))) }) }, title))) }));
};
export default TjenesterList;
