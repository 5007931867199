import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// Løses i forbindelse med #282399
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/role-has-required-aria-props */
import { useEffect, useState, useRef } from 'react';
import classNames from 'classnames';
import { Icon, IconSize } from '@helsenorge/designsystem-react/components/Icon';
import ChevronLeft from '@helsenorge/designsystem-react/components/Icons/ChevronLeft';
import ChevronRight from '@helsenorge/designsystem-react/components/Icons/ChevronRight';
import HelpSign from '@helsenorge/designsystem-react/components/Icons/HelpSign';
import X from '@helsenorge/designsystem-react/components/Icons/X';
import aria from '@helsenorge/core-utils/aria-hidden';
import TrapFocus from '@helsenorge/core-utils/trapfocus';
import { Breakpoint, theme, useBreakpoint, useFocusToggle, useHover } from '@helsenorge/designsystem-react';
import { trackProsesshjelp } from '@helsenorge/framework-utils/adobe-analytics';
import { setAllowNavigation } from '@helsenorge/framework-utils/hn-user';
import { Seksjon } from './seksjon';
import styles from './styles.module.scss';
import './styles.scss';
export const HjelpeskuffComponent = props => {
    const { isVisible = true, defaultSeksjon, resources, seksjoner } = props;
    const breakpoint = useBreakpoint();
    const [disableTransition, setDisableTransition] = useState(false);
    const { hoverRef, isHovered } = useHover();
    const helpTitleRef = useRef(null);
    const helpDrawerRef = useRef(null);
    const helpContentsRef = useRef(null);
    const [prevAriaHiddens, setPrevAriaHiddens] = useState();
    const [trapFocus, setTrapFocus] = useState();
    const [prevFocus, setPrevFocus] = useState();
    useFocusToggle(helpContentsRef, props.isOpen);
    const trackToggleOpen = (activeSeksjonId, isOpen) => {
        let activeSeksjon;
        if (activeSeksjonId && seksjoner) {
            activeSeksjon = seksjoner.find((seksjon) => {
                return activeSeksjonId === seksjon.hjelpetekstId;
            });
        }
        else {
            activeSeksjon = props.defaultSeksjon;
        }
        if (activeSeksjon && activeSeksjon.overskrift) {
            trackProsesshjelp('Prosesshjelp', 'Hjelp', activeSeksjon.overskrift, isOpen ? 'Open' : 'Close');
        }
    };
    const enableTrapFocus = () => {
        if (trapFocus) {
            return; // Focus already trapped
        }
        setPrevFocus(document.activeElement);
        setTrapFocus(new TrapFocus(helpDrawerRef.current));
    };
    const toggleFocus = () => {
        if (props.isOpen && isVisible) {
            enableTrapFocus();
            const node = helpDrawerRef.current;
            if (node instanceof Element) {
                setPrevAriaHiddens(aria.setAriaHidden(node));
                if (helpTitleRef.current) {
                    helpTitleRef.current.focus();
                }
            }
        }
    };
    const releaseFocus = () => {
        if (prevAriaHiddens) {
            aria.resetAriaHidden(prevAriaHiddens);
        }
        if (trapFocus) {
            trapFocus.deactivate();
            setTrapFocus(undefined);
            if (prevFocus) {
                prevFocus.focus();
            }
            setPrevFocus(undefined);
        }
    };
    const handleToggleClick = () => {
        if (props.toggleShowHjelpeskuff) {
            props.toggleShowHjelpeskuff();
        }
    };
    const elementIsInHelpDrawer = (eventTarget) => {
        for (const target of eventTarget) {
            if (target?.classList?.contains('org_helpdrawer')) {
                return true;
            }
        }
        return false;
    };
    const elementIsInHjelpetrigger = (eventTarget) => {
        for (const target of eventTarget) {
            if (target?.classList?.contains('js-prosesshjelp')) {
                return true;
            }
        }
        return false;
    };
    const isMobile = () => {
        return breakpoint <= Breakpoint.sm;
    };
    const isOpenInHistoryState = () => {
        return window.history.state && window.history.state.helpDrawerOpen;
    };
    const removePreventscroll = () => {
        document.documentElement.classList.remove('preventscroll');
    };
    const isLightboxOpen = () => {
        return document.querySelectorAll('.toolkit-lightbox').length > 0;
    };
    const setScroll = () => {
        if (document.documentElement.classList.contains('preventscroll') && !props.isOpen && !isLightboxOpen()) {
            removePreventscroll();
        }
        else {
            document.documentElement.classList.add('preventscroll');
        }
    };
    const open = () => {
        // Only push state to enable back/forward browser navigation if help drawer use the full width (mobile)
        if (isMobile() && !isOpenInHistoryState()) {
            window.history.pushState(Object.assign({}, window.history.state, { helpDrawerOpen: true }), '', window.location.href);
        }
        setScroll();
    };
    const close = () => {
        if (isMobile() && isOpenInHistoryState()) {
            setAllowNavigation(true);
            window.history.back();
        }
        if (!isLightboxOpen()) {
            removePreventscroll();
        }
    };
    const disableTransitionToPreventDoubleAnimation = (callback) => {
        setDisableTransition(true);
        callback();
        window.setTimeout(() => {
            setDisableTransition(false);
        }, 500);
    };
    const popStateListener = () => {
        setAllowNavigation(false);
        const helpDrawerOpen = window.history.state && window.history.state.helpDrawerOpen;
        if (helpDrawerOpen && !props.isOpen && props.showHjelpeskuff) {
            disableTransitionToPreventDoubleAnimation(props.showHjelpeskuff);
        }
        else if (!helpDrawerOpen && props.isOpen && props.hideHjelpeskuff) {
            disableTransitionToPreventDoubleAnimation(props.hideHjelpeskuff);
        }
    };
    const clickListener = (e) => {
        if (isVisible && props.isOpen) {
            const eventPath = e.composedPath();
            if (!elementIsInHelpDrawer(eventPath) && !elementIsInHjelpetrigger(eventPath) && props.hideHjelpeskuff) {
                props.hideHjelpeskuff();
            }
        }
    };
    const removeSeksjon = (triggerId, tjenesteNavn) => {
        toggleFocus();
        if (props.removeSeksjon) {
            props.removeSeksjon(triggerId, tjenesteNavn);
        }
    };
    useEffect(() => {
        toggleFocus();
    }, []);
    useEffect(() => {
        if (props.isOpen) {
            window.addEventListener('popstate', popStateListener);
            window.addEventListener('click', clickListener);
            toggleFocus();
            open();
            trackToggleOpen(props.activeSeksjonId, props.isOpen);
        }
        else {
            releaseFocus();
            close();
        }
        return () => {
            releaseFocus();
            window.removeEventListener('popstate', popStateListener);
            window.removeEventListener('click', clickListener);
        };
    }, [props.isOpen, prevFocus]);
    if (!isVisible || !resources) {
        return null;
    }
    const helpDrawerClasses = classNames({
        org_helpdrawer: true,
        open: props.isOpen,
    });
    let defaultSeksjonComponent;
    let seksjonerComponent;
    if (defaultSeksjon != null) {
        defaultSeksjonComponent = (_jsx(Seksjon, { id: "default", seksjon: defaultSeksjon, expanded: !!defaultSeksjon.expanded, resources: resources, toggleExpanded: props.toggleExpanded }));
    }
    if (seksjoner != null) {
        seksjonerComponent = (_jsx("div", { children: seksjoner.map((seksjon, index) => (_jsx(Seksjon, { id: index.toString(), seksjon: seksjon, expanded: !!seksjon.expanded, resources: resources, removeSeksjon: removeSeksjon, toggleExpanded: props.toggleExpanded }, index.toString()))) }));
    }
    let style = {};
    if (disableTransition) {
        style = { transitionDuration: '0s' };
    }
    const ariaLabelTitleText = defaultSeksjon && defaultSeksjon.overskrift
        ? defaultSeksjon.overskrift
        : seksjoner && seksjoner.length && seksjoner[0].overskrift
            ? seksjoner[0].overskrift
            : '';
    return (_jsxs("div", { ref: helpDrawerRef, className: helpDrawerClasses, style: style, children: [_jsx("span", { className: "org_helpdrawer__control", children: _jsxs("button", { className: "org_helpdrawer__controlbutton", type: "button", onClick: handleToggleClick, tabIndex: 0, "aria-label": props.isOpen ? resources.helpDrawerCloseHelpButton : resources.helpDrawerToggleHelpButton, "data-testid": 'helpdrawer', children: [_jsx(Icon, { svgIcon: HelpSign, color: theme.palette.white, className: 'org_helpdrawer__icon' }), props.isOpen ? (_jsx(Icon, { svgIcon: ChevronRight, color: theme.palette.white })) : (_jsx(Icon, { svgIcon: ChevronLeft, color: theme.palette.white }))] }) }), _jsxs("div", { className: "org_helpdrawer__contents", "aria-hidden": !props.isOpen, ref: helpContentsRef, children: [_jsxs("div", { className: "org_helpdrawer__drawerheader", children: [_jsxs("div", { className: "helptitle-wrap", role: "heading", tabIndex: 0, "aria-label": `${resources.helpDrawerHelpIconLabel} ${ariaLabelTitleText}`, ref: helpTitleRef, children: [_jsx(Icon, { svgIcon: HelpSign, color: theme.palette.white, className: 'org_helpdrawer__icon' }), _jsx("h1", { className: "helptitle", children: resources.helpDrawerTitle })] }), _jsxs("button", { ref: hoverRef, className: styles['close-button'], onClick: props.hideHjelpeskuff, type: "button", children: [_jsx(Icon, { size: IconSize.XSmall, svgIcon: X, color: theme.palette.white, isHovered: isHovered }), _jsx("span", { className: styles['close-button__screen-reader-text'], children: resources.helpDrawerCloseHelpButton })] })] }), _jsxs("div", { children: [defaultSeksjonComponent, seksjonerComponent] })] })] }));
};
export default HjelpeskuffComponent;
